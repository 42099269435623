// Colors

@max-width: 1350px;
@padding-page: 0 15px;

:root {
	--color-white: #fff;

	--color-background: #e7edef;
	--color-transparent: transparent;
	--color-lightness: #f1f6f7;
	--color-light: #f2f6fa;

	--color-dark: #3d4b52;

	--color-grey: #b2c7cb;
	--color-light-grey: #e7edef;
	--color-darkness-grey: #78909c;
	--color-dark-grey: #4a4a4a;

	--color-red: #ff5254;
	--color-red-light: #fce7e7;
	--color-red-dark: #c84042;

	--col-orange-50: #fff7ed;
	--col-orange-100: #ffedd5;
	--col-orange-200: #fed7aa;
	--col-orange-300: #fdba74;
	--col-orange: #fb923c;
	--col-orange-500: #f97316;
	--col-orange-600: #ea580c;
	--col-orange-700: #c2410c;
	--col-orange-800: #9a3412;
	--col-orange-900: #7c2d12;
	--col-orange-950: #431407;

	--col-blue-50: #eff6ff;
	--col-blue-100: #dbeafe;
	--col-blue-200: #bfdbfe;
	--col-blue-300: #93c5fd;
	--col-blue: #60a5fa;
	--col-blue-500: #3b82f6;
	--col-blue-600: #2563eb;
	--col-blue-700: #1d4ed8;
	--col-blue-800: #1e40af;
	--col-blue-900: #1e3a8a;
	--col-blue-950: #172554;

	--col-green-50: #f0fdf4;
	--col-green-100: #dcfce7;
	--col-green-200: #bbf7d0;
	--col-green-300: #86efac;
	--col-green: #4ade80;
	--col-green-500: #22c55e;
	--col-green-600: #16a34a;
	--col-green-700: #15803d;
	--col-green-800: #166534;
	--col-green-900: #14532d;
	--col-green-950: #052e16;

	--col-primary-50: #fff0f1;
	--col-primary-100: #ffe1e3;
	--col-primary-200: #ffc8ce;
	--col-primary-300: #ff9ba6;
	--col-primary-400: #ff6378;
	--col-primary-500: #ff2c4e;
	--col-primary-600: #f60838;
	--col-primary: #d30030;
	--col-primary-800: #ae032f;
	--col-primary-900: #940730;
	--col-primary-950: #530015;

	--col-second-50: #f4f6f7;
	--col-second-100: #e2e8eb;
	--col-second-200: #c9d4d8;
	--col-second-300: #a3b5bd;
	--col-second: #78909c;
	--col-second-500: #5b737f;
	--col-second-600: #4e606c;
	--col-second-700: #43515b;
	--col-second-800: #3c454e;
	--col-second-900: #363d43;
	--col-second-950: #21262b;

	--col-gray-50: #f9fafb;
	--col-gray-100: #f3f4f6;
	--col-gray-200: #e5e7eb;
	--col-gray-300: #d1d5db;
	--col-gray: #9ca3af;
	--col-gray-500: #6b7280;
	--col-gray-600: #4b5563;
	--col-gray-700: #374151;
	--col-gray-800: #1f2937;
	--col-gray-900: #111827;
	--col-gray-950: #030712;

	--col-slate-50: #f8fafc;
	--col-slate-100: #f1f5f9;
	--col-slate-200: #e2e8f0;
	--col-slate-300: #cbd5e1;
	--col-slate: #94a3b8; // Rock Blue
	--col-slate-500: #64748b;
	--col-slate-600: #475569;
	--col-slate-700: #334155;
	--col-slate-800: #1e293b;
	--col-slate-900: #0f172a;
	--col-slate-950: #020617;


	--gradient-primary: linear-gradient(45deg, var(--col-primary) 0, var(--col-primary-500) 100%);
	--gradient-secondary: linear-gradient(-45deg, var(--col-primary) 0, var(--col-second) 100%);

	--gradient-radial:  radial-gradient(57.11% 57.11% at 50% 50.22%, var(--color-grey) 0%, var(--color-darkness-grey) 100%);

	--fw-bold: "Gilroy-Bold", sans-serif;
	--fw-black: "Gilroy-Vlack", sans-serif;
}

@media only screen and (max-width: 576px) {
	:root {
		.row {
			--bs-gutter-x: 0.5rem;
			--bs-gutter-y: 0;
		}
	}
}

/*
	sm: 576px,
	md: 768px,
	lg: 992px,
	xl: 1200px,
	xxl: 1400px
 */
