.product-item-detail-slider {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	max-height: 850px;
	width: 100%;
	overflow: hidden;

	.product-item-detail-slider-container {
		//max-width: 700px;
		position: relative;
		background: transparent;
		transition: background 800ms ease;
		max-height: 850px;
		overflow: hidden;
		border: 1px solid var(--color-background);

		.slider-arrows-main {
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: 50%;
			width: 100%;
		}

		.slider-arrow-main {
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: 50%;
			z-index: 10;
			border: none;
			opacity: 0;
			transform: translateY(-50%);
			transition: all 0.3s;
			border-radius: 50%;
			width: 34px;
			height: 34px;

			.slider-icon {
				width: 16px;
				height: 16px;
				fill: var(--color-darkness-grey);
				transition: all 0.3s;
			}

			&:disabled,
			[disabled] {
				.slider-icon {
					fill: var(--color-darkness-grey);
					opacity: 0.5;
				}
			}

		}

		&:hover {
			.slider-arrow-main {
				opacity: 0.9
			}
		}
	}

	.product-item-detail-slider-main {
		//width: calc(100% - 110px);
		position: relative;
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		max-height: 800px;

		.tns-item {
			display: flex !important;
		}
	}

	.product-gallery-main {

	}

	.product-item-detail-slider-main-el {

	}

	.product-item-detail-slider-main-photo {
		max-height: 800px;
		width: 100%;
		border-radius: 10px;
		//max-width: 700px;
	}

	.product-item-detail-slider-previews {

		position: relative;
		max-width: 763px;
		max-height: 100px;
		overflow: hidden;

		.slider-arrows {
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: 50%;
			width: 100%;
			opacity: 0;
			transition: all 0.3s;
		}

		.slider-arrow {
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			top: 50%;
			z-index: 10;
			width: 26px;
			height: 26px;
			border: none;
			background-color: var(--color-light);
			border-radius: 4px;
			transform: translateY(-50%);

			.slider-icon {
				width: 16px;
				height: 16px;
				fill: var(--color-darkness-grey);
				transition: all 0.3s;
			}

			&:disabled,
			[disabled] {
				.slider-icon {
					fill: var(--color-darkness-grey);
					opacity: 0.5;
				}
			}
		}

		&:hover {
			.slider-arrows {
				opacity: 0.9
			}
		}
	}

	.product-item-detail-slider-previews-outer {

	}

	.product-item-detail-slider-previews-el {
		border-radius: 10px;
		cursor: pointer;
		opacity: 0.5;

		&.--selected {
			opacity: 1;
		}

		img {
			border-radius: 10px;
		}
	}

	.product-item-detail-slider-previews-el-link {

	}

	.--prev {
		left: 10px;
	}

	.--next {
		right: 10px;
	}

	.tns-ovh {
		border-right: none
	}

	.tns-nav {
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 15px
	}

	.tns-nav button {
		width: 30px;
		height: 10px;
		margin: 0 5px;
		padding: 0;
		border-radius: 5px;
		border: 2px solid  var(--color-light);
	}

	.tns-nav button.tns-nav-active {
		background: var(--col-second);
		border: 2px solid var(--col-second);
	}
}


@media (max-width: 441px) {

	.product-item-detail-slider {
		justify-content: center;

		.product-item-detail-slider-previews {
			display: none;
		}

		.product-item-detail-slider-container {
			max-width: 450px;

			.slider-arrow-main {
				opacity: 1;
			}
		}
	}

	.product-item-detail-pay-block {
		margin-top: 25px;
	}
}
