.header {
	display: block;
	width: 100%;
	top: 0;
	z-index: 1039;

	//-----------------------------------------------

	.top-bar {
		min-height: 40px;
		max-height: 40px;
		background: var(--col-slate-50);
		display: flex;
		align-items: center;

		.top-bar__inner {
			width: 100%;
		}

		.nav-bar {
			margin-top: auto;
			margin-bottom: auto;
			display: flex;
			justify-content: space-between;
			list-style: none;
			padding: 0;

			.nav-bar__item {
				line-height: 14px;
			}

			.nav-bar__link {
				display: flex;
				width: 100%;
				height: 100%;
				text-align: center;
				text-decoration: none;
				color: var(--color-darkness-grey);
				align-items: center;
				transition: .3s linear;

				svg {
					margin-right: 10px;
					fill: var(--color-red);
				}

				&:hover {
					text-decoration: underline;
					color: var(--col-primary);
				}
			}
		}
	}

	//-----------------------------------------------

	.pre-header {
		width: 100%;
		height: 100px;
		background: var(--color-white);
	}

	@media only screen and (max-width: 768px) {
		.pre-header {
			height: 60px;
			background: var(--color-white);
		}

		.pre-header__text {
			display: flex;
			margin-left: 20px;
		}
	}

	.pre-header__inner {

	}

	.pre-header__nav {
		position: relative;
		display: flex;
		justify-content: space-between;
		width: 100%;
		max-width: @max-width;
		align-items: center;
		align-content: center;
	}

	@media only screen and (max-width: 768px) {
		.pre-header__nav {
			flex-wrap: wrap;
			align-content: center;
			justify-content: center;
		}
	}


	.pre-header__nav-toggler {
		padding: 5px 10px;
		display: block;
		color: var(--color-dark);
		display: none; /////!!!!!
	}


	.pre-header__mobile-menu {
		display: none;
	}


	.pre-header__nav-logo {

		.brand-logo {
			display: block;
			width: 250px;

			.brand-logo__img {
				fill: var(--col-primary);
				width: 100%;
				height: 100%;
			}
		}
	}

	.pre-header__text {
		display: none;
	}

	@media only screen and (max-width: 576px) {
		.pre-header__nav-logo {
			.brand-logo {
				width: 200px;
			}
		}
		.pre-header__text {
			display: block;
		}
	}

	.pre-header__nav-city {
		display: flex;
		align-items: center;
		gap: 10px;
		position: relative;

		.city-icon {
			fill: var(--col-primary);
		}

		.city-address {
			display: flex;
			flex-direction: column;
			gap: 5px;
			line-height: 1;

			span {
				font-size: 12px;
				color: var(--color-darkness-grey);
			}
		}
	}

	@media only screen and (max-width: 1200px) {
		.pre-header__nav-city-selection {
			display: none;
		}
	}

	.pre-header__nav-messengers {
		display: none;
		// display: flex;
		align-items: center;
		gap: 10px;
		position: relative;

		.messengers-wa {
			width: 36px;
			position: relative;

			span {
				width: 14px;
				height: 14px;
				position: absolute;
				top: -5px;
				right: -5px;
				font-style: normal;
				font-weight: 600;
				font-size: 10px;
				line-height: 14px;
				display: flex;
				align-items: center;
				text-align: center;
				color: var(--color-white);
				background: var(--color-red);
				justify-content: center;
				border-radius: 50%;
				animation: blinking 3s ease-in-out infinite;
			}
		}

		.messengers-tg {
			width: 36px;
		}

		.messengers-text {
			//position: absolute;
			//bottom: -25px;
			//left: 0;
			font-size: 12px;
			line-height: 12px;
		}
	}


	.pre-header__nav-contacts {
		position: relative;
		display: flex;
		align-items: center;
		width: max-content;
		gap: 15px;

		.phone-numbers {
			display: flex;
			flex-direction: column;
		}

		.phone-text {
			position: relative;
			padding-left: 12px;
			font-size: 12px;
			line-height: 1;

			&::before {
				width: 7px;
				height: 7px;
				background: var(--col-green);
				position: absolute;
				content: '';
				border-radius: 50%;
				top: calc(50% - 4px);
				left: 0;
				animation: blinking 3s ease-in-out infinite;
			}
		}

		.phone-number {
			display: flex;
			flex-direction: column;
			line-height: 1;
			gap: 2px;

			a {
				font-size: 18px;
				color: var(--color-dark);
				font-weight: 700;
				text-decoration: none;
				transition: .3s linear;

				span {
					font-size: 24px;
					font-weight: 700;
				}

				&:hover {
					color: var(--col-primary);
				}
			}
		}

		.phone-icon {
			fill: var(--col-primary);
		}

		.phone-time {
			font-weight: 400;
			font-size: 12px;
			color: var(--color-darkness-grey);
		}

		.phone-info {
			margin-right: 20px;
		}

		.phone-info-icon {
			fill: var(--color-grey);
			cursor: pointer;

			&:hover {
				fill: var(--col-primary);
			}
		}

		.phone-informer-modal {
			position: absolute;
			top: 40px;
			left: -50%;
			display: none;
			width: 600px;
			background-color: var(--color-white);
			box-shadow: 0 12px 24px rgb(0 0 0 / 20%);
			z-index: 2000;
		}

		.phone-informer-modal__content {
			position: relative;
			display: flex;
			padding: 20px;
			flex-direction: column;
		}

		.phone-informer-modal__header {
			padding-bottom: 20px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			border-bottom: 1px solid var(--color-light);
			font-size: 20px;
		}

		.phone-informer-modal__close-btn {
			background: transparent;
			border: none;
			cursor: pointer;
			fill: var(--color-grey);

			&:hover {
				fill: var(--col-primary);
			}
		}

		.phone-informer-modal__body {
			padding-top: 20px;
			display: flex;
			justify-content: space-between;
			width: 100%;
		}

		.phone-informer-modal__numbers {
			display: flex;
			flex-direction: column;
		}

		.phone-informer-modal__phone-number {
			color: var(--color-dark);
			display: flex;
			flex-direction: column;

			a {
				font-size: 18px;
				font-weight: 700;
				text-decoration: none;
				color: var(--color-dark);

				&:hover {
					color: var(--col-primary);
				}
			}

			span {
				font-size: 14px;
				color: var(--color-grey);
			}
		}

		.phone-informer-modal__features {
			display: flex;
			flex-direction: column;
			margin-bottom: 10px;
			width: 50%;
		}

		.phone-informer-modal__features-item {
			display: flex;
			flex-direction: column;
		}

		.phone-informer-modal__feature-header {
			font-size: 18px;
		}

		.phone-informer-modal__feature-text {
			font-size: 14px;
			color: var(--color-grey);
		}
	}

	.pre-header__nav-feedback {
		.feedback-btn {
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 30px;
			height: 36px;
			flex-shrink: 0;
			font-weight: 400;
			line-height: 1;
			border: 1px solid var(--col-primary);
			background: 0 0;
			color: var(--col-primary);
			border-radius: 5px;
			text-decoration: none;
			cursor: pointer;
			transition: .3s;

			svg {
				fill: currentColor;
				margin-right: 15px;
			}

			&:hover {
				background: var(--col-primary);
				color: var(--color-white);
			}

			&:active {
				color: var(--color-white);
				box-shadow: 5px 5px 5px rgba(9,12,18,.15) inset;
				background: var(--col-primary-900);
				border-color: var(--col-primary-900);
			}
		}
	}

	.pre-header__nav-bar {
		width: 100%;
		display: flex;
		justify-content: flex-end;

		.nav-bar {
			margin-top: auto;
			margin-bottom: auto;
			display: flex;
			justify-content: space-between;
			margin-left: 100px;
			list-style: none;
			padding: 0;
			width: 100%;

			.nav-bar__item {
				display: flex;
				line-height: 14px;

				&:last-child {
					//
				}
			}

			@media only screen and (max-width: 576px) {
				.nav-bar__item {
					padding: 0;

					&.--extra {
						padding: 0;
						margin-top: 5px;
					}
				}
			}

			.nav-bar__link {
				font-size: 16px;
				display: flex;
				width: 100%;
				height: 100%;
				text-align: center;
				text-decoration: none;
				color: var(--color-dark);
				align-items: center;
				transition: all .3s linear;

				&:hover {
					color: var(--col-primary);
					text-decoration: underline;
				}

				svg {
					margin-right: 10px;
					fill: var(--color-red);
				}
			}


			.free-call__number {
				color: var(--color-dark);
				font-weight: 700;
				text-decoration: none;
				margin: 0 5px;
				transition: all .3s linear;

				span {
					transition: all .3s;
					font-size: 18px;
					font-weight: 700;
					color: var(--col-primary);
				}

				&:hover {
					span {
						color: var(--col-primary-900);
					}
				}
			}

			@media only screen and (max-width: 576px) {
				.free-call__number {
					color: var(--color-grey);
					font-size: 14px;

					span {
						color: var(--color-grey);
						font-size: 14px;
					}
				}
			}
		}
	}


	@media only screen and (max-width: 1200px) {
		.pre-header__nav-bar {
			.nav-bar {
				padding-left: 0;
			}
		}
	}

	@media only screen and (max-width: 1024px) {
		.pre-header__nav-bar {
			.nav-bar {
				.nav-bar__item {
					padding-top: 5px;
					padding-bottom: 5px;
					display: none;

					&.--extra {
						display: flex;
					}
				}
			}
		}
	}

	@media only screen and (max-width: 992px) {
		.pre-header__nav-bar {

		}
	}

	@media only screen and (max-width: 768px) {
		.pre-header__nav-bar {
			width: 100%;
			-ms-flex-order: 5;
			order: 5;
		}
	}


	.pre-header__nav-adaptive {
		.user-tools {
			margin-top: auto;
			margin-bottom: auto;
			display: flex;
			list-style: none;

			.user-tools__item {
				position: relative;
				margin-left: 8px;
				margin-right: 8px;
			}

			.user-tools__btn {
				padding: 12px 4px;
				display: block;
				color: var(--color-dark);

				.user-tools__btn-icon {
					fill: var(--color-dark-grey);
				}
			}

			.user-tools__counter {
				position: absolute;
				top: 5px;
				right: -4px;
				display: flex;
				justify-content: center;
				width: 16px;
				height: 16px;
				font-size: 10px;
				line-height: 18px;
				text-align: center;
				font-weight: 600;
				color: var(--color-white);
				background-color: var(--col-primary);
				border-radius: 50%;
			}
		}
	}

	@media only screen and (max-width: @max-width)
	{
		.pre-header__nav-adaptive {
			.user-tools {
				padding-left: 0;
			}
		}

		.social-list_header {
			padding-left: 0;
		}
	}

	@media only screen and (min-width: 992px)
	{
		.pre-header__nav-adaptive {
			display: none;
		}
	}


	//------------------

	.header-bar {
		transition: all .3s linear;
		width: 100%;
		background: var(--col-slate-200);

		&.--fixed {
			display: block;
			position: fixed;
			top: 0;
			background: var(--color-white);
			box-shadow: 0 10px 20px 0 rgb(61 75 82 / 20%);
		}
	}

	.header-bar__inner {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 30px;
		height: inherit;
		margin: 0 auto;
		width: 100%;
		flex-shrink: 0;
	}

	.header-menu {
		display: flex;
		justify-content: space-between;
		width: initial;
		align-items: center;
		padding: 10px 0;
		gap: 20px;
	}


	.header-menu__catalog {

		.catalog-nav {
			position: relative;
			display: block;

			&.--show-menu {
				.header-menu__list {
					display: block;
				}
			}
		}

		.catalog-nav__btn {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 200px;
			height: 40px;
			flex-shrink: 0;
			font-weight: normal;
			line-height: 1;
			background: var(--col-primary);
			color: var(--color-white);
			text-decoration: none;
			transition: all .3s ease;

			&:hover {
				background: var(--col-primary-900);
			}
		}

		.catalog-nav__btn-icon-block {
			display: table-cell;
			vertical-align: middle;
		}

		.--loader {
			width: 16px;
			height: 16px;
			margin-right: 15px;
			position: relative;
		}

		.--loader:after,
		.--loader > div,
		.--loader > div > span,
		.--loader > div > span:after{
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
		}

		.--loader:after{
			content: "";
			border: 3px solid var(--color-white);
			-moz-border-radius: 50%;
			-webkit-border-radius: 50%;
			border-radius: 50%;
		}

		.--loader > div{
			z-index: 1;
			-webkit-animation: loaderRotate1 4.8s linear infinite;
			animation: loaderRotate1 4.8s linear infinite;
		}

		.--loader > div > span{
			clip: rect(0, 16px, 16px, 7.5px);
			-webkit-animation: loaderRotate2 1.2s linear infinite;
			animation: loaderRotate2 1.2s linear infinite;
		}

		.--loader > div > span:after{
			content: "";
			clip: rect(0, 16px, 16px, 7.5px);
			border: 3px solid var(--color-grey);
			-moz-border-radius: 50%;
			-webkit-border-radius: 50%;
			border-radius: 50%;
			-webkit-animation: loaderRotate3 1.2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
			animation: loaderRotate3 1.2s cubic-bezier(0.770, 0.000, 0.175, 1.000) infinite;
		}

		.catalog-nav__btn-icon {
			fill: currentColor;
			margin-right: 15px;
		}

		.catalog-nav__btn-text {
			font-size: 14px;
			font-weight: 700;
			text-transform: uppercase;
		}
	}

	// ----------- Mobile
	.header-menu__catalog_mobile {
		display: none;

		.catalog-nav {
			position: relative;
			display: block;

			&.--show-menu {
				.header-menu__list {
					display: block;
				}
			}
		}

		.catalog-nav__btn {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100px;
			height: 40px;
			flex-shrink: 0;
			font-weight: normal;
			line-height: 1;
			background: var(--col-primary);
			color: var(--color-white);
			text-decoration: none;
			transition: all .3s ease;

			&:hover {
				background: var(--col-primary-900);
			}
		}

		.catalog-nav__btn-icon-block {
			display: table-cell;
			vertical-align: middle;
		}

		.catalog-nav__btn-icon {
			fill: currentColor;
			margin-right: 15px;
		}

		.catalog-nav__btn-text {
			font-size: 14px;
			font-weight: 700;
			text-transform: uppercase;
		}
	}

	@media only screen and (max-width: 768px) {
		.header-menu__catalog_mobile {
			display: flex;
		}
	}


	.header-menu__search {
		width: inherit;

		.header-menu__search-form {
			display: flex;
			border: 2px solid var(--color-grey);
			border-radius: 5px;
			transition: all .3s;
			position: relative;

			&:hover {
				border-color: var(--color-grey);
			}

			&:focus {
				border-color: var(--color-grey);
			}
		}

		.header-menu__search-form-input {
			flex-grow: 1;
			width: 100%;
			height: 36px;
			padding: 0 15px;
			border-radius: 3px 0 0 3px;
			border: none;
			color: var(--color-darkness-grey);
			font-size: 14px;
		}

		.header-menu__search-btn {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 50px;
			background: var(--color-white);
			border-color: transparent;
			border-radius: 0 3px 3px 0;
			transition: all .3s;
			fill: var(--color-grey);

			&:hover {
				fill: var(--col-primary);
			}
		}
	}

	.header-search_mobile {
		display: none;
	}


	.header-menu__free-call {
		height: 40px;
		display: flex;
		align-content: center;
		align-items: center;

		.header-menu__free-call-block {
			position: relative;
			display: flex;
			align-items: center;
			width: max-content;
			height: 40px;
		}

		.header-menu__free-call-numbers {
			display: flex;
			flex-direction: column;
			flex-shrink: 0;
			align-items: flex-start;
			justify-content: center;
		}

		.header-menu__free-call-number {
			color: var(--color-dark);
			font-size: 20px;
			text-decoration: none;
			transition: all .3s linear;

			&:hover {
				color: var(--col-primary);
			}
		}

		.header-menu__free-call-back {
			color: var(--color-dark);
			text-decoration: none;
			transition: all .3s linear;

			&:after {
				content: "";
				display: flex;
				border-bottom: 1px dashed var(--color-dark);
			}

			&:hover {
				color: var(--col-primary);

				&:after {
					border-bottom: 1px dashed var(--col-primary);
				}
			}
		}
	}

	@media only screen and (max-width: 992px) {
		.header-menu__free-call {
			display: none;
		}
	}

	.header-menu__mobile-call {
		display: none;
	}

	.header-menu__mobile-call-link {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 38px;
		height: 38px;
		flex-shrink: 0;
		font-weight: normal;
		line-height: 1;
		background: var(--col-primary);
		color: var(--color-white);
		text-decoration: none;
		transition: all .3s ease;
		fill: var(--color-white);

		&:hover {
			background: var(--col-primary-900);
		}
	}

	@media only screen and (max-width: 992px) {
		.header-menu__mobile-call {
			display: flex;
		}
	}

	.header-menu__list {
		display: none;
		background-color: #fff;
		border-radius: 8px;
		left: 0;
		margin-top: 8px;
		padding: 8px 0;
		position: absolute;
		top: 40px;
		width: 240px;
		z-index: 1;
		box-shadow: 0 14px 25px rgba(0,51,153,.16);

		.header-menu__item {
			list-style: none;
			position: relative;
			transition: background-color .2s linear;

			&:hover {
				background: var(--color-light);
			}

			&.--active {
				font-weight: 600;
			}
		}

		.header-menu__link {
			font-size: 16px;
			color: var(--color-dark);
			text-decoration: none;
			display: block;
			padding: 12px 16px;
		}
	}

	.header-menu__user-tools {
		.user-tools {
			margin-top: auto;
			margin-bottom: auto;
			display: flex;
			gap: 10px;
			list-style: none;
			padding: 0;
		}

		.user-tools__item {
			position: relative;
		}

		.user-tools__btn {
			padding: 12px 4px;
			display: block;
			fill: var(--color-dark);

			&:hover {
				fill: var(--col-primary);
			}
		}

		.user-tools__counter {
			position: absolute;
			top: 5px;
			right: -4px;
			display: flex;
			justify-content: center;
			width: 16px;
			height: 16px;
			font-size: 10px;
			line-height: 18px;
			text-align: center;
			font-weight: 500;
			color: var(--color-white);
			background-color: var(--col-primary);
			border-radius: 50%;
		}
	}

	@media only screen and (max-width: 992px) {
		.header-menu__user-tools {
			display: none;
		}
	}

	.cart-menu {
		position: relative;
		display: block;
		height: 100%;
		flex-shrink: 0;

		&:hover {
			.cart-menu__hover-block {
				opacity: 1;
				visibility: visible;
			}
		}

		.cart-menu__btn {
			padding-left: 20px;
			padding-right: 20px;
			display: flex;
			width: 100%;
			height: 100%;
			align-items: center;
			border: none;
			line-height: 1;
			color: var(--color-white);
			background-color: var(--col-primary);
		}

		.cart-menu__icon {
			display: block;
			fill: var(--color-white);
		}

		.cart-menu__info {
			padding-left: 15px;
			flex-shrink: 0;
			text-align: left;
		}

		.cart-menu__msg {
			display: block;
			font-size: 14px;
			font-weight: normal;
			line-height: 18px;
		}

		.cart-menu__cost {
			font-size: 16px;
			line-height: 1;
		}

		.cart-menu__mobile-btn {
			display: none;
			width: 44px;
			height: 44px;
			align-items: center;
			border: none;
			line-height: 1;
			color: var(--color-white);
			background-color: var(--col-primary);
			margin-top: 4px;
			padding-left: 8px;
			padding-right: 8px;
			justify-content: center;
			border-radius: 4px;
		}

		.cart-menu__mobile-counter {
			position: absolute;
			top: 5px;
			right: -4px;
			display: none;
			justify-content: center;
			width: 16px;
			height: 16px;
			font-size: 10px;
			line-height: 18px;
			text-align: center;
			font-weight: 500;
			color: var(--color-white);
			background-color: var(--col-primary);
			border-radius: 50%;
		}

		@media only screen and (max-width: 576px) {
			.cart-menu__btn {
				display: none;
			}

			.cart-menu__mobile-btn {
				display: flex;
			}
				.cart-menu {
					height: inherit;
				}
		}

		.cart-menu__hover-block {
			position: absolute;
			top: 100%;
			right: 0;
			width: 565px;
			visibility: hidden;
			opacity: 0;
			transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out;
			transition-delay: 0.2s;
			box-shadow: 0 8px 20px #00339914, 0 4px 8px #00339914;
			border-radius: 4px;
			z-index: 2;

			.basket {
				margin: 0;
				padding: 0;
				list-style-type: none;
				position: relative;

				> li {
					position: relative;
					display: flex;
					margin: 0;

					&.item {
						border-bottom: 1px solid var(--col-slate-100);
					}
				}

				.basket-item {
					display: flex;
					width: 100%;
					flex-direction: row;
					align-items: center;
					gap: 15px;
					padding: 10px;
				}

				.basket-item-del {
					position: absolute;
					top: 10px;
					right: 10px;
					cursor: pointer;
					font-size: 2em;
					color: var(--col-slate);
				}

				.basket-item-image {
					max-height: 70px;
				}

				.basket-item-description {
					width: 100%;
				}

				.basket-item-name {
					font-size: 1rem;
				}

				.basket-item-summary {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
				}

				.basket-item-quantity,
				.basket-item-amount {
					font-weight: 600;

					span {
						display: block;
						color: var(--col-slate);
						font-weight: 400;
					}
				}
			}

			.cart-empty {
				padding: 60px 0 0 0;
				width: 100%;
			}

			.cart-empty__picture {
				position: relative;

				svg {
					fill: var(--color-lightness);
					width: 72px;
					margin: 0 auto;
					height: 72px;
					display: block;
				}
			}

			.cart-empty__info {
				text-align: center;

				.title {
					display: block;
					font-size: 1.3em;
					margin: 1.6rem 0 1.06rem;
					color: var(--col-slate-600);
					line-height: normal;
					text-transform: none;
					letter-spacing: normal;
					padding: 0;
					font-weight: normal;
				}
			}

			.cart-empty__btn {
				font-size: 12px;
				background-color: transparent;
				width: 100%;
				border-color: transparent;
				color: var(--color-dark-grey);
				padding-top: 23px;
				padding-bottom: 21px;
				position: relative;
				border-radius: 0 0 3px 3px;
				display: block;
				text-decoration: none;
				text-transform: uppercase;
				transition: all .3s;

				&:hover {
					color: var(--color-white);
					background: var(--col-primary);
				}
			}

			.cart-order {
				padding: 60px 0 0 0;
				width: 100%;
			}

			.cart-order__info {
				text-align: center;

				.title {
					display: block;
					font-size: 1.3em;
					margin: 1.6rem 0 1.06rem;
					color: var(--col-slate-600);
					line-height: normal;
					text-transform: none;
					letter-spacing: normal;
					padding: 0;
					font-weight: normal;
				}
			}

			.cart-order__btn {
				font-size: 12px;
				background-color: var(--col-primary-600);
				width: 100%;
				color: var(--color-white);
				padding-top: 23px;
				padding-bottom: 21px;
				position: relative;
				border-radius: 0 0 3px 3px;
				display: block;
				text-align: center;
				text-decoration: none;
				text-transform: uppercase;
				transition: all .3s;

				&:hover {
					background: var(--col-primary-800);
				}
			}
		}
	}

	.header-fixed {
			width: 100%;
			background-color: var(--color-white);
			height: 60px;
			display: none;

			&.--fixed {
				display: block;
				position: fixed;
				top: 0;
				box-shadow: 0 10px 20px 0 rgb(61 75 82 / 20%);
			}
	}

	.header-fixed__inner {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 30px;
		height: inherit;
		margin: 0 auto;
		width: 100%;
		flex-shrink: 0;
	}
}

@media only screen and (max-width: 576px) {
	.header {
		.top-bar,
		.pre-header__nav-city,
		.pre-header__nav-contacts,
		.pre-header__nav-feedback,
		.header-menu__catalog {
			display: none;
		}

		.header-menu {
			width: 100%;
		}

		.header-menu__search {
			display: none;
		}

		.header-search_mobile {
			fill: var(--col-second-700);
			display: flex;
		}

		.header-bar__inner {
			gap: 5px;
		}

		.cart-menu {
			height: inherit;

			.cart-menu__mobile-btn {
				background: none;
			}

			.cart-menu__mobile-counter {
				display: flex;
			}

			.cart-menu__icon {
				fill: var(--col-second-700);
			}
		}
	}
}
