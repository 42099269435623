.futures {
	width: 100%;

	.futures__inner {
		width: 100%;

		&:hover {
			.slider-controls {
				opacity: 1;
			}
		}
	}

	.futures__container {
		width: 100%;
		padding-right: calc(var(--bs-gutter-x) * .5);
		padding-left: calc(var(--bs-gutter-x) * .5);
		margin-right: auto;
		margin-left: auto;
	}

	.futures__item {
		position: relative;
		display: flex;
		align-items: center;
		flex-direction: column;
		width: 100%;
		height: 100%;
		padding: 10px;
		border: 1px solid var(--color-white);
		border-radius: 10px;
		transition: all .3s;
		background: var(--col-primary);
		color: var(--color-white);
		fill: var(--color-white);

		//border-color:  var(--col-primary);

		&:hover {

			border-color:  var(--col-primary-900);
			background: var(--color-white);
			color: var(--color-dark);



			.futures__item-icon svg {
				fill: var(--col-primary);
			}
		}
	}

	@media only screen and (max-width: 576px) {
		.futures__item {
			margin-bottom: 25px;
		}
	}

	.futures__item-screen {
		position: absolute;
		z-index: 10;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.futures__item-icon {
		height: 110px;
		width: 140px;
		overflow: hidden;
		position: relative;
	}

	.futures__item-icon svg {
		position: absolute;
		top: 50%;
		left: 50%;
		height: 80px;
		fill: var(--color-white);
		transform: translate(-50%,-50%);
		transition: all .3s;
	}

	.futures__item-name {
		font-size: 16px;
		font-weight: 600;
		margin: 0 0 10px;
		text-align: center;
		position: relative;
		display: inline-block;
	}

	.futures__item-name-span {
		transition: all .3s;
		position: relative;
	}

	.futures__item-desc {
		font-size: 13px;
		font-weight: 300;
		text-align: center;
		margin: 10px 0;
		line-height: 13px;
	}

	@media only screen and (max-width: 576px) {
		.futures__item-name {
			font-size: 12px;
			margin: 0;
			line-height: 1;

			&:before {
				content: inherit;
			}
		}

		.futures__item-desc {
			display: none;
		}

		.futures__item {
			&:hover {
				.futures__item-name {
					.futures__item-name-span {
						color: var(--col-primary);
					}
				}
			}
		}
	}

	&.--white {

		.futures__item {
			border: 1px solid var(--color-light-grey);
			background: var(--color-white);
			color: var(--color-dark);
			fill: var(--col-primary);
		}

		.futures__item-icon svg {
			fill: var(--col-primary);
		}

	}
}
