.catalog_item_inner {

	&:hover {
		.footer_button {
			opacity: 1;
			visibility: visible;
			z-index: 20;
		}
	}
}

.card-tile .edit-doc-trigger {
	top: -25px;
	right: -25px;
}

.edit-doc-trigger {
	position: absolute;
	font-size: 10px;
	top: 10px;
	right: 10px;
	background: rgba(255,0,0,0.1);
	padding: 5px 10px;
	border-radius: 3px;
	z-index: 100;
}

.item_card {
	position: relative;
	display: flex;
	width: 100%;
	background: var(--color-white);
	padding: 20px 15px;
	flex-direction: column;
	border-radius: 10px;
	box-shadow: 0 4px 16px #0033990a, 0 2px 2px #00339914;
	transform: translateY(0);
	transition: transform .15s ease-out, box-shadow .15s ease-out;

	&:hover {
		z-index: 20;
		box-shadow: 0 8px 20px #00339914, 0 4px 8px #00339914;
		transform: translateY(-2px);
	}

}

.item_card_label {
	position: absolute;
	top: 15px;
	left: 15px;
	z-index: 10;

	ul {
		list-style: none;
		padding: 0;
	}
}

.card_label_list .card_label_item {
	position: relative;
	display: flex;
	align-items: center;
	margin-bottom: 5px
}

.card_label_list .card_label_item:hover .card_label_item_hint {
	opacity: 1
}

.card_label_list .card_label_item_body {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	height: 24px;
	padding: 0 15px;
	border-radius: 3px;
	cursor: pointer;
	transition: .3s;
	line-height: 1;
}

.card_label_list .card_label_item_hint {
	position: absolute;
	left: 55px;
	display: flex;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
	height: 20px;
	padding: 0 10px;
	border-radius: 3px;
	background: rgba(247,247,247,0.8);
	color: var(--color-dark);
	font-size: 11px;
	font-weight: 500;
	opacity: 0;
	transition: all .3s;
	-webkit-user-select: none;
	user-select: none;
	pointer-events: none
}

.card_label_list .--reg .card_label_item_body {
	background: var(--col-slate-500);
	color: var(--color-white);
	font-size: 12px;
	font-weight: 300
}

.card_label_list .--3d .card_label_item_body {
	background: var(--col-orange);
	color: var(--color-white);
	font-size: 12px;
	font-weight: 100
}

.card_label_list .--vid .card_label_item_body {
	background: var(--col-primary-500);
	color: var(--color-white);
	font-size: 12px;
	font-weight: 300
}

.card_label_list .--new .card_label_item_body {
	background: var(--col-green-500);
	color: var(--color-white);
	font-size: 12px;
	font-weight: 300
}

.item_card_specials {
	position: absolute;
	top: 15px;
	right: 15px;
	z-index: 10
}

.item_card_specials .specials_label_discount {
	background: var(--color-white);
	color: #c84042;
	border: 1px solid #c84042;
	font-size: 12px;
	font-weight: 300;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	padding: 0 5px;
	height: 20px;
	border-radius: 3px
}

.card_inner {
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: column;
	margin: 0 auto
}

.card_img_link {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0 auto;
	overflow: hidden
}

.card_img_link {
	min-width: 60px;
	margin: 0 auto;
	padding: 0;
	text-align: center;
	zoom: 1;
	display: block;
	left: 0;
}

.card_img_link {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	width: 100%;
}

.card_img_link {
	background-position: 50% 50%;
	background-repeat: no-repeat;
}

.card_img_wrapper_block, .card_img_wrapper_block > a {
	min-width: 60px;
	margin: 0 auto;
	padding: 0;
	text-align: center;
	zoom: 1;
	display: flex;
	left: 0;
	justify-content: center;
}

.card_img_wrapper_block {
	margin: 0 auto 20px;
	position: relative;
}

.card_img_wrapper_block {
	padding-top: 100%;
}

.card_img_wrapper_block {
	width: 100%;
}

.card_img {
	max-height: 100%;
	max-width: 100%
}

.card_img {
	position: absolute;
	margin: auto;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	border: 0;
	vertical-align: middle;
	max-width: 100%;
	max-height: 100%;
	pointer-events: none;
}

.card_img {
	max-width: 100%;
	max-height: 100%;
}

.card_img_video {
	position: absolute;
	bottom: 10px;
	right: 5px;
	height: 40px;
	margin: 0;
	z-index: 5;
	padding: 5px;
	border: 1px solid var(--color-light-grey);
	background: var(--color-white);
	border-radius: 5px;
	fill: var(--col-primary);
	cursor: pointer;
	opacity: .5;
	transition: all .5s;

	a {
		display: flex;
		justify-content: center;
		align-items: center;
		text-decoration: none;
		color: var(--color-dark);
	}

	span {
		font-size: 12px;
		margin: 0 10px 0 5px;
		display: none;
		transition: all .5s;
	}

	&:hover {
		opacity: 1;
		border-color: var(--col-primary);

		span {
			display: flex;
		}
	}
}

.card_category {
	font-size: 14px;
	font-weight: 200;
	color: #ccc;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap
}

.card_name {
	position: relative;
	display: block;
	height: 50px;
	overflow: hidden;
	margin-bottom: 5px;
	padding-bottom: 1px;
	font-size: 16px;
	font-weight: 700;
	color: var(--color-dark);
	transition: all .3s;
	line-height: 18px;
	text-decoration: none;

	&.--catalog {
		display: flex;
		flex-direction: column;
		align-content: center;
		align-items: center;
		text-align: center;
	}

	.card_name_title {
		font-size: 20px;
	}

	.card_name_sign {
		color: #78909c;
	}

	&:hover {
		text-decoration: none;
	}
}

@media(max-width: 768px) {
	.card_name {
		height: 60px;

		.card_name_title {
			font-size: 16px;
		}
	}
}

.card_info {
	font-size: 12px;
	font-weight: 300;
	display: flex;
	margin-bottom: 10px;
	color: #636363
}

.card_info_articul {
	color: var(--col-slate);
	overflow: hidden;
	white-space: nowrap;
	padding-right: 10px
}

.card_info_stock {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-bottom: 15px;
}

.card_info_stock.--instock {
	color: var(--col-green-500);
	fill: var(--col-green-500);
}

.card_info_stock.--outtock i {
	color: var(--col-primary-500)
}

.card_footer {
	display: flex;
	flex-direction: column;

	.card_footer_price {
		font-size: 38px;
		font-weight: 700;

		span {
			font-size: 20px;
		}
	}

	.card_footer_price_sign {
		font-size: 14px;
		margin-bottom: 10px;
		color: var(--color-grey);
	}

	.card_footer_full {
		display: flex;
		width: 100%;
	}

	.sale_block {
		margin: 2px 0 2px;
		font-size: 10px;

		.sale-number {
			display: inline-block;
			padding: 1px;
			background: var(--color-white);
			white-space: nowrap;
		}

		.value {
			display: inline-block;
			padding: 0 3px 0 5px;
		}

		.inner-sale {
			background: var(--color-white);
			display: inline-block;
			padding: 2px 5px 1px;
		}
	}

	.card_footer_sales {
		display: flex;
		align-items: flex-start;
		height: 20px;
		margin-bottom: 10px;

	}

	.card_footer_sales_old {
		padding-top: 3px;
		line-height: 1;
		text-decoration: line-through;
		color: var(--color-grey);
	}

	.card_footer_sales_discount {
		margin-left: 10px;
		padding: 3px 7px;
		font-size: 12px;
		line-height: 1;
		border-radius: 2px;
		color: var(--color-white);
		background-color: var(--col-green-500);
	}
}

.footer_button {
	opacity: 0;
	visibility: hidden;
	height: 0;
	transition: all .1s ease;
	text-align: center;

	.btn {
		display: block;
		background-color: var(--color-grey);
		border-color: var(--color-grey);
		color: var(--color-white);
		border-radius: 0 0 3px 3px;
		text-decoration: none;
	}
}

.catalog_item {
	margin-bottom: 25px;
	position: relative;
}


@media(max-width: 768px) {
	.item_card_label {
		top: 10px;
		left: 10px;
	}

	.card_label_list {
		.card_label_item_body {
			height: 18px;
			padding: 0 10px;
			font-size: 10px;
		}
	}

	.card_name {
		font-size: 14px;
	}

	.card_footer .card_footer_left .card_price {
		font-size: 24px;
	}

	.card_footer {

		.card_footer_sales_old,
		.card_footer_sales_discount{
			font-size: 12px;
		}

		.card_footer_price {
			font-size: 24px;

			span {
				font-size: 16px;
			}
		}

		.card_footer_price_sign {
			font-size: 12px;
			line-height: 1;
		}
	}
}

@media(max-width: 441px) {
	.item_card_label {
		top: 10px;
		left: 10px;
	}

	.card_label_list {
		.card_label_item_body {
			height: 18px !important;;
			padding: 0 10px !important;;
			font-size: 10px !important;
		}
	}

	.card_name {
		font-size: 12px;
		line-height: 1;
	}

	.card_info_articul {
		font-size: 10px;
	}

	.card_info {
		margin-bottom: 0;
	}

	.card_info_stock {
		margin-bottom: 10px;
	}

	.card_status-icon {
		max-width: 16px;
	}

	.card_status-text {
		font-size: 12px;
	}

	.card_category {
		font-size: 13px;
	}

	.card_footer .card_footer_left .card_price {
		font-size: 20px;
	}

	.item_card {
		padding: 10px;
	}

	.catalog_item {
		margin-bottom: 20px;
	}
}

.add-cart {
	display: flex;
	flex-direction: column;
	gap: 10px;

	.add-cart-body {
		display: flex;
		flex-direction: column;
		gap: 10px;
	}

	.add-cart-article {
		color: var(--col-slate);
	}

	.add-cart-title {
		font-size: 1.5rem;
		line-height: 1;
	}

	.add-cart-price {
		font-weight: 700;
		font-size: 2.2rem;

		span {
			font-size: 1.2rem;
		}
	}

	.add-cart-footer {
		display: flex;
		gap: 10px;
	}
}
