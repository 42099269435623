.item-carousel {
	//position: relative;

	&:after {
		content: "";
		display: table;
		clear: both;
	}

	picture {
		display: none;
		top: 0;
		left: 0;

		&:first-child {
			display: block;
		}
	}

	.tmb-wrap {
		position: absolute;
		z-index: 2;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;

		.tmb-wrap-table {
			display: flex;
			justify-content: space-evenly;
			height: 100%;
			width: 100%;

			div {
				position: relative;
				width: 100%;
				transition: all .3s ease;

				&::after {
					//background-color: #ccc;
					content: "";
					left: 1px;
					right: 1px;
					top: 0;
					position: absolute;
					transition: background-color .3s ease-out;
					height: 2px;
				}

				&.active {
					&::after {
						//background-color: #0a53be;
					}
				}

				&:first-child:last-child {
					border-color: transparent;
				}
			}
		}
	}

	.image-wrap {
		height: 100%;
	}

	.image-nav-wrap {
		position: absolute;
		bottom: 10px;
		left: 0;
		display: flex;
		flex-wrap: nowrap;
		justify-content: center;
		width: 100%;
		z-index: 3;

		div {
			background-color: var(--color-grey);
			border-radius: 50%;
			width: 5px;
			height: 5px;
			margin: 2px;

			&.active {
				background-color: var(--color-dark-grey);
			}
		}
	}
}

@media (max-width: 441px) {
	.item-carousel {
		.image-nav-wrap {
			bottom: 0;
		}
	}
}