.tns {
	--bs-gutter-x: 1.5rem;
	--bs-gutter-y: 0;
}

.tns-outer {
	padding:0!important;
}

.tns-outer [hidden] {
	display:none!important;
}

.tns-outer [aria-controls],.tns-outer [data-action] {
	cursor:pointer;
}

.tns-slider {
	-webkit-transition:all 0;
	-moz-transition:all 0;
	transition:all 0;
}

.tns-slider>.tns-item {
	-webkit-box-sizing:border-box;
	-moz-box-sizing:border-box;
	box-sizing:border-box;
}

.tns-horizontal.tns-subpixel>.tns-item {
	//display:inline-block;
	display: inline-flex;
	vertical-align:top;
	white-space:normal;
	justify-content: center;
	align-items: center;
}

.tns-horizontal.tns-no-subpixel>.tns-item {
	float:left;
}

.tns-horizontal.tns-carousel.tns-no-subpixel>.tns-item {
	margin-right:-100%;
}

.tns-no-calc {
	position:relative;
	left:0;
}

.tns-gallery {
	position:relative;
	left:0;
	min-height:1px;
}

.tns-gallery>.tns-item {
	position:absolute;
	left:-100%;
	-webkit-transition:transform 0 opacity 0;
	-moz-transition:transform 0 opacity 0;
	transition:transform 0 opacity 0;
}

.tns-gallery>.tns-slide-active {
	position:relative;
	left:auto!important;
}

.tns-gallery>.tns-moving {
	-webkit-transition:all .25s;
	-moz-transition:all .25s;
	transition:all .25s;
}

.tns-lazy-img {
	-webkit-transition:opacity .6s;
	-moz-transition:opacity .6s;
	transition:opacity .6s;
	opacity:0.6;
}

.tns-lazy-img.tns-complete {
	opacity:1;
}

.tns-ah {
	-webkit-transition:height 0;
	-moz-transition:height 0;
	transition:height 0;
}

.tns-ovh {
	height: 100%;
	overflow:hidden;
}

.tns-inner {
	height: 100%;
}

.tns-visually-hidden {
	position:absolute;
	left:-10000em;
}

.tns-transparent {
	opacity:0;
	visibility:hidden;
}

.tns-fadeIn {
	opacity:1;
	filter:alpha(opacity=100);
	z-index:0;
}

.tns-normal,.tns-fadeOut {
	opacity:0;
	filter:alpha(opacity=0);
	z-index:-1;
}

.tns-t-subp2 {
	width:310px;
	position:relative;
	height:10px;
	overflow:hidden;
	margin:0 auto;
}

.tns-t-ct {
	width:calc(100%*70/3);
	position:absolute;
	right:0;
}

.tns-t-ct>div {
	width:calc(100%/70);
	height:10px;
	float:left;
}

.tns-wrapper-negative {
	margin-left:-15px;
	margin-right:-15px;
}

.tns-wrapper-negative .row {
	margin-left:0;
	margin-right:0;
}

div .tns-item {
	flex:none;
	visibility: hidden;
	transition: all .3s;
}

.tns-horizontal.tns-subpixel,.tns-vpfix {
	white-space:nowrap;
}

.tns-horizontal.tns-no-subpixel:after,.tns-t-ct:after {
	content:'';
	display:table;
	clear:both;
}

.tns-autowidth,.tns-vpfix>div,.tns-vpfix>li {
	display:inline-block;
}

.--bs .tns-inner {
	margin-right: calc(-.5 * var(--bs-gutter-x)) !important;
	margin-left: calc(-.5 * var(--bs-gutter-x)) !important;
}

.tns-slider {
	margin-left: 0;
	margin-right: 0;
}

.tns-controls {
	position: absolute;
}

.tns-nav {
	text-align: center;
	margin: 10px 0;
	position: absolute;
	display: flex;
	width: 100%;
	justify-content: center;
	flex-direction: row;
	left: 0;

	> [aria-controls] {
		width: 20px;
		height: 5px;
		padding: 0;
		margin: 0 5px;
		border-radius: 5px;
		background: var(--color-light-grey);
		border: 0;
	}

	> .tns-nav-active {
		background: var(--col-primary);
	}
}


.tns-slide-cloned {
	visibility: hidden;
}

.tns-slide-active {
	visibility: visible !important;
}


.main-slider-container {
	position: relative;
	overflow: hidden;
}

.main-slider-nav {
	position: absolute;
	top: calc(50% - 12px);
	width: 100%;

	.main-slider-prev {
		left: 10px;
		position: absolute;
		fill: var(--color-white);
		cursor: pointer;
	}

	.main-slider-next {
		right: 10px;
		position: absolute;
		fill: var(--color-white);
		cursor: pointer;
	}
}
.main-slider {
	button {
		border: 0;
		background: transparent;
	}

	.tns-nav {
		bottom: 0;
		z-index: 100;
	}
}
