.hnews {

	.hnews__item {
		background: var(--color-white);
		color: var(--color-dark);
		display: block;
		text-decoration: none;
		border-radius: 10px;
		transition: all .2s ease-out;
		line-height: 1;
		box-shadow: 0 4px 16px #0033990a, 0 2px 2px #00339914;
		transform: translateY(0);
		transition: transform .15s ease-out, box-shadow .15s ease-out;

		&:hover {
			box-shadow: 0 8px 20px #00339914, 0 4px 8px #00339914;
			transform: translateY(-2px);
		}

		padding: 15px;
	}

	.hnews__item-img {
		border-radius: 4px 4px 0 0;
		display: flex;
		overflow: hidden;
		position: relative;
		width: 100%;
		max-height: 220px;
		justify-content: center;
		align-items: center;

		.lazyload {
			height: 220px;
		}
	}

	.hnews__item-date {
		border-radius: 4px;
		color: var(--color-grey);
		padding: 15px 0 0;
		display: block;
	}

	.hnews__item-img img {

		&.lazyloaded {
			object-fit: cover;
			width: 100%;
			max-height: 100%;
			height: 170px;
		}
	}

	.hnews__item-info {
		display: block;
		padding: 20px 0 0;
	}

	.hnews__item-info_name {
		display: block;
		font-size: 14px;
		font-weight: 700;
		height: 40px;
	}

	.hnews__item-info_text {
		color: var(--color-dark);
		display: block;
		font-size: 13px;
		margin-top: 15px;
		line-height: 1.4;
		height: 100px;
	}
}

.news__list {

	.news-image {
		max-height: 250px;
	}

	.news-image img {
		object-fit: cover;
		width: 100%;
		max-height: 100%;
		height: 200px;
		border-radius: 10px;
	}

	.news-data {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;
		padding: 0 25px;
		background: var(--color-white);
		border-radius: 10px;
		box-shadow: 0 8px 20px #00339914, 0 4px 8px #00339914;

		.news-header {
			font-size: 18px;
			font-weight: 700;
			margin-bottom: 10px;
			display: block;
		}

		.news-date {
			font-size: 14px;
			color: var(--color-grey);
			margin-bottom: 10px;
			display: block;
		}
	}

	@media(max-width: 576px) {
		.news-image {
			margin-bottom: 15px;
		}
	}
}