.shadow-block {
	background-color: rgba(0,0,0,.5);
	transition: all .3s;
	position: absolute;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 1047;
	backdrop-filter: blur(2px);

	&.--slide-panel {
		display: block!important;
		z-index: 1099;
	}
}

.shadow-block {
	display: none;
}