.slide-panel {
	position: fixed;
	max-width: 400px;
	min-width: 350px;
	height: 100%;
	right: -410px;
	top: 0;
	background: var(--color-white);
	transition: right .3s ease 0s;

	&.--active {
		position: fixed;
		right: 0;
		z-index: 1100;
	}

	.slide-panel-loader {
		width: 48px;
		height: 48px;
		position: absolute;
		left: 50%;
		top: 50%;
	}

	//<div class="slide-panel-loader"><div><span></span></div></div>
	.slide-panel-loader {
		transform: translate(-50%,-50%);

		> div {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			z-index: 1;
			animation: loaderRotate1 4.8s linear infinite;

			> span {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				clip: rect(0,48px,48px,24px);
				animation: loaderRotate2 1.2s linear infinite;

				&::after {
					content: "";
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					clip: rect(0,48px,48px,24px);
					border: 3px solid #6639b6;
					border-radius: 50%;
					animation: loaderRotate3 1.2s cubic-bezier(.77,0,.175,1) infinite;
				}
			}

		}

		&::after {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			content: "";
			border: 3px solid #e3ecef;
			border-radius: 50%;
		}
	}

	.slide-panel-title-wrap {
		height: 60px;
		display: flex;
		align-items: center;
		padding: 0 72px 0 24px;
		position: relative;
		background-color: #f1f6f7;
	}

	.slide-panel-title-icon {
		padding-right: 18px;
		fill: var(--color-grey);
	}

	.slide-panel-title {
		font-size: 22px;
		line-height: 24px;
		color: var(--color-dark);
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	.slide-panel-close  {
		width: 72px;
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		position: absolute;
		right: 0;
		top: 0;
		cursor: pointer;

		&:hover {
		  .slide-panel-close-icon {
			fill: var(--color-red-dark);
		  }
		}
	}

	.slide-panel-close-icon {
		fill: var(--color-grey);
	}

	.scroll-wrapper {
		padding: 0!important;
		position: relative;
		overflow: hidden!important;
	}

	.slide-panel-content {
		height: calc(100% - 72px);
		position: relative;
		font-size: 16px;

		> .slide-panel-content {
		  padding: 0 32px;
		}
	}

	.slide-panel-contacts {
		margin-left: -32px;
		margin-right: -32px;
	}

	.slide-panel-contacts-item {
		border-bottom: 1px solid var(--color-light-grey);
		padding: 15px 32px;
	}

	.slide-panel-contacts-item-today-container {
		width: 100%;
	}

	.slide-panel-contacts-item-today {
		display: flex;
		align-items: center;
		position: relative;
	}

	.slide-panel-contacts-item-today-icon {
		padding-right: 15px;
	}

	.slide-panel-contacts-item-today-title {
		text-align: left;
		width: 100%;
		white-space: nowrap;
		padding-right: 25px;
	}

	.slide-panel-contacts-item-today-status {
		width: 10px;
		height: 10px;
		position: absolute;
		right: 2px;
		border-radius: 5px;

		&.--open {
		  background: var(--col-second);
		}
	}

	.slide-panel-contacts-item-hours-break {
		width: 100%;
		padding-left: 5px;
	}

	.slide-panel-contacts-item-hours {
		display: flex;
		align-items: center;

		&.--first {

		}
	}

	.slide-panel-contacts-item-hours-title {
		width: 100%;
	}

	.slide-panel-contacts-item-block {
		display: flex;
		align-items: center;
	}

	.slide-panel-contacts-item-icon {
		padding-right: 15px;
		fill: var(--color-grey);
	}

	.slide-panel-contacts-item-text {
		width: 100%;
		color: var(--color-dark);
	}

	.slide-panel-contacts-item-phone {
		font-size: 20px;
		line-height: 24px;
		display: block;

		&.--link {
			color: var(--color-red-dark);
			text-decoration: none;

			&:hover {
				color: var(--color-red);
			}
		}
	}

	.slide-pane-contacts-item-descr {
		display: block;
		font-size: 14px;
		color: #8ea6ab;
		line-height: 17px;
	}


	.slide-panel-form {
		margin-top: 24px;

		.slide-panel-form-caption {
			margin-bottom: 18px;
		}

		.btn {
			width: 100%;
		}
	}

	label.control-label {
		margin-bottom: 5px;
		color: var(--color-grey);
		font-weight: 700;
	}
}

.modal {
	backdrop-filter: blur(2px);
}

.modal-header {
	border-bottom-color: var(--col-slate-100);
}
