.btn:hover {
	border-color: transparent;
}

.btn-buy,
.btn-buy:focus,
a.btn-buy:focus,
a.btn-buy:link,
a.btn-buy:visited {
	color: var(--color-white);
	background-color: var(--col-primary);
	border: 1px solid var(--col-primary);
	fill: currentColor;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 15px;
	padding: 10px 0;
	border-radius: 5px;
	text-decoration: none;
	transition: all .3s;

	&:hover {
		background-color: var(--col-primary-900);
		border-color: var(--col-primary-900);
	}

	&:active {
		color: var(--color-white);
		box-shadow: 5px 5px 5px rgba(9,12,18,.15) inset;
		background: var(--col-primary-900);
		border-color: var(--col-primary-900);
	}

	&.--in-cart {
		background-color: var(--col-green-600);
		border-color: var(--col-green-600);

		&:hover {
			background-color: var(--col-green-700);
			border-color: var(--col-green-700);
		}

		&:active {
			background-color: var(--col-green-800);
			border-color: var(--col-green-800);
		}
	}
}

.o-btn-buy,
.o-btn-buy:focus,
a.o-btn-buy:focus,
a.o-btn-buy:link,
a.o-btn-buy:visited {
	color: var(--col-primary-500);
	background-color: var(--color-white);
	border: 1px solid var(--col-primary-500);
	fill: currentColor;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 15px;
	padding: 10px 0;
	border-radius: 5px;
	text-decoration: none;
	transition: all .3s;

	&:hover {
		color: var(--color-white);
		background-color: var(--col-primary);
		border-color: var(--col-primary);
	}

	&:active {
		color: var(--color-white);
		box-shadow: 5px 5px 5px rgba(9,12,18,.15) inset;
		background: var(--col-primary-900);
		border-color: var(--col-primary-900);
	}

	&.disabled {
		pointer-events: none;
		opacity: .5;
		cursor: not-allowed;
	}
}

.o-btn-muted,
.o-btn-muted:focus,
a.o-btn-muted:focus,
a.o-btn-muted:link,
a.o-btn-muted:visited {
	color: var(--col-slate);
	background-color: var(--color-white);
	border: 1px solid var(--col-slate);
	fill: currentColor;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 15px;
	padding: 10px 0;
	border-radius: 5px;
	text-decoration: none;
	transition: all .3s;

	&:hover {
		color: var(--color-white);
		background-color: var(--col-slate-500);
		border-color: var(--col-slate-500);
	}

	&:active {
		color: var(--color-white);
		box-shadow: 5px 5px 5px rgba(9,12,18,.15) inset;
		background: var(--col-slate-600);
		border-color: var(--col-slate-600);
	}

	&.disabled {
		pointer-events: none;
		opacity: .5;
		cursor: not-allowed;
	}
}

.btn-muted,
.btn-muted:focus,
a.btn-muted:focus,
a.btn-muted:link,
a.btn-muted:visited {
	color: var(--color-white);
	background-color: var(--col-slate);
	border: 1px solid var(--col-slate);
	fill: currentColor;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 15px;
	padding: 10px 0;
	border-radius: 5px;
	text-decoration: none;
	transition: all .3s;

	&:hover {
		color: var(--color-white);
		background-color: var(--col-slate-500);
		border-color: var(--col-slate-500);
	}

	&:active {
		color: var(--color-white);
		box-shadow: 5px 5px 5px rgba(9,12,18,.15) inset;
		background: var(--col-slate-600);
		border-color: var(--col-slate-600);
	}
}

.btn-info,
.btn-info:focus,
a.btn-info:focus,
a.btn-info:link,
a.btn-info:visited {
	color: var(--color-white);
	background-color: var(--col-blue-500);
	border: 1px solid var(--col-blue-500);
	fill: currentColor;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 15px;
	padding: 10px 0;
	border-radius: 5px;
	text-decoration: none;
	transition: all .3s;

	&:hover {
		color: var(--color-white);
		background-color: var(--col-blue-600);
		border-color: var(--col-blue-600);
	}

	&:active {
		color: var(--color-white);
		box-shadow: 5px 5px 5px rgba(9,12,18,.15) inset;
		background: var(--col-blue-700);
		border-color: var(--col-blue-700);
	}
}

@media(max-width: 441px) {
	.btn-buy,
	a.btn-buy,
	.btn-muted,
	a.btn-muted,
	.o-btn-muted,
	a.o-btn-muted {
		padding: 5px 0 !important;
		font-size: 12px;

		svg {
			max-height: 14px;
		}
	}
}
