@font-face {
	font-display: swap;
	font-family: ruble;
	font-style: normal;
	font-weight: 400;
	src: url(../fonts/rub/rub.woff2) format("woff2")
}

@font-face {
	font-display: swap;
	font-family: Gilroy;
	font-style: normal;
	font-weight: 500;
	src: url(../fonts/gilroy-regular.woff2) format("woff2"),url(../fonts/gilroy-regular.woff) format("woff"),url(../fonts/gilroy-regular.ttf) format("truetype")
}

@font-face {
	font-display: swap;
	font-family: Gilroy;
	font-style: normal;
	font-weight: 700;
	src: url(../fonts/gilroy-bold.woff2) format("woff2"),url(../fonts/gilroy-bold.woff) format("woff"),url(../fonts/gilroy-bold.ttf) format("truetype")
}

@font-face {
	font-display: swap;
	font-family: Gilroy;
	font-style: normal;
	font-weight: 900;
	src: url(../fonts/gilroy-black.woff2) format("woff2"),url(../fonts/gilroy-black.woff) format("woff"),url(../fonts/gilroy-black.ttf) format("truetype")
}