.typeahead {
	&.dropdown-menu {
		width: 100%;
		display: grid;
		gap: 0;
		grid-template-columns: 1fr;
		border: 0;
		box-shadow: 0 16px 32px #0033990a,0 20px 20px #00339914;
		top: 48px !important;
	}

	a {
		display: flex;
		flex-direction: inherit;
		margin: 0;
		padding: 9px 16px;
		max-width: unset;
		max-height: 64px;
		-webkit-align-items: center;
		align-items: center;
		border: none;
	}

	.dropdown-footer__link {
		color: var(--color-white);
		background: var(--col-primary);
		border-radius: 5px;
		display: flex;
		justify-content: center;
		text-decoration: none;

		&:hover {
			background: var(--col-primary-800);
		}
	}

	.product__divider {
		display: flex;
		align-items: center;
		color: var(--col-slate-50);
		white-space: nowrap;
		font-size: 12px;
		font-weight: 400;
		line-height: 18px;
		text-transform: uppercase;
	}

	.product__image-wrapper {
		height: 48px;
		max-width: 48px;
		margin: 0;
	}

	.product__image {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: contain;
		object-position: center;
	}

	.product__data {
		margin-left: 16px;
		width: 100%;
		display: flex;
		flex-direction: column;
		gap: 5px;
	}
	.product__price {
		display: flex;
		gap: 20px;
	}

	.product__price_actual {
		font-weight: 700;
		color: var(--col-primary);
	}

	.product__price_old {
		font-weight: 700;
		color: var(--col-slate-500);
		text-decoration: line-through;
	}
}

.mobile-search {
	cursor: default;
	position: fixed;
	display: none;
	align-items: flex-start;
	justify-content: center;
	inset: 100% 0px 0px;
	width: 100vw;
	height: 100vh;
	background-color: rgba(15, 15, 16, 0.6);
	z-index: 1050;
	padding: 40px 36px 0px;
	top: 0;

	&.active {
		display: flex;
	}

	.mobile-search-block {
		transition: background 0.2s ease-out, border 0.2s ease-out;
		will-change: background, border;
		width: 100%;
		cursor: text;
		position: relative;
		display: flex;
		border: none;
		background: #fff;
		color: #0f0f10;
		text-align: left;
		font-family: 'Roboto', sans-serif;
		font-size: 18px;
		line-height: 24px;
		height: 56px;
		border-radius: 5px;
	}

	.mobile-search-wrapper {

		transition: padding 0.2s ease-out, color 0.2s ease-out;
		will-change: padding, color;
		display: flex;
		flex-direction: column;
		width: 100%;
		max-height: 100%;
		padding: 16px 24px;
	}

	.mobile-search-input {
		border: 0;
		transition: opacity 0.2s ease-out;
		will-change: opacity;
		width: 100%;
		background: transparent;
		overflow: hidden;
		text-overflow: ellipsis;
		margin: auto;
		opacity: 1;
	}

	.mobile-search-close {
		position: absolute;
		top: 50%;
		right: 16px;
		transform: translateY(-50%);
	}

	.mobile-search-close-btn {
		border: 0;
		background: var(--col-second-100);
		border-radius: 5px;
		display: flex;
		height: 24px;
		width: 24px;
		align-items: center;
		justify-content: center;
	}
}

@media only screen and (max-width: 576px) {
	.product__title {
		display: flex;
		font-size: 14px;
		white-space: normal;
		line-height: 1;
	}

	.typeahead.dropdown-menu {
		gap: 15px;
		top: 64px !important;
		left: 0 !important;
	}

	.typeahead a {
		max-height: 84px;
	}

	.typeahead .product__image-wrapper {
		height: 60px;
		max-width: 60px;
	}
}
