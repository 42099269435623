.widget {
	z-index: 1000;
	position: relative;
}
.widget .widget_wrapper {
	position: fixed;
	bottom: 30px;
	right: 25px;
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100000;
}
.widget.widget_animate .widget_btn_label {
	animation: widget_btn-pulsate 2s ease-out;
	animation-iteration-count: infinite;
}
.widget:hover .widget_btn_label {
	animation: none;
}
.widget .widget_btn_input {
	display: none;
}
.widget .widget_btn_input:checked+label {
	background: var(--color-white) !important;
	animation: none;
}
.widget .widget_btn_input:checked+label .widget_icon-write {
	opacity: 0;
	visibility: hidden;
	transform: scale(.1);
}
.widget .widget_btn_input:checked+label .widget_icon-close {
	opacity: 1;
	visibility: visible;
	transform: scale(1);
}
.widget .widget_btn_input:checked~.widget_icon_link {
	opacity: 1;
	visibility: visible;
}
.widget .widget_btn_input:checked~a:nth-of-type(1) {
	transition: all 0.2s ease-in-out;
	transform: translate(0,-135%);
}
.widget .widget_btn_input:checked~a:nth-of-type(2) {
	transition: all 0.225s ease-in-out;
	transform: translate(0,-255%);
}
.widget .widget_btn_input:checked~a:nth-of-type(3) {
	transition: all 0.225s ease-in-out;
	transform: translate(0,-375%);
}
.widget .widget_btn_label {
	position: relative;
	cursor: pointer;
	z-index: 2;
	width: 60px;
	height: 60px;
	justify-content: center;
	align-items: center;
	background: var(--color-brand-primary);
	border-radius: 50%;
	transition: all 0.2s ease-in-out;
	box-shadow: 0 0 20px 0 rgba(0,0,0,0.3);
	overflow: hidden;
}
.widget .widget_btn_label:hover {
	box-shadow: 0 0 20px 0 rgba(0,0,0,0.4);
}
.widget .widget_icon {
	transition: all 0.3s ease-in-out;
	position: absolute;
	margin: auto;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}
.widget .widget_icon-telegram_wrapper {
	background: #40B3E0;
}
.widget .widget_icon-close {
	opacity: 0;
	visibility: hidden;
	transform: scale(.1);
}
.widget .widget_icon_link {
	border-radius: 50%;
	box-shadow: 0 0 20px 0 rgba(0,0,0,0.3);
	opacity: 0;
	visibility: hidden;
	width: 50px;
	height: 50px;
}
.widget .widget_wrapper,
.widget .widget_tooltip {
	user-select: none;
}
.widget .t-records a[href^=tel] {
	color: inherit;
	text-decoration: none;
}
.widget .widget_tooltip {
	position: absolute;
	right: 70px;
	white-space: nowrap;
	background: var(--color-white);
	padding: 6px 9px;
	font-size: 13px;
	border-radius: 3px;
	transform: translateX(0) translateY(-50%);
	top: 50%;
	color: #292929;
	opacity: 0;
	transition: .1s linear;
	box-shadow: 0 0 8px 1px rgba(0,0,0,0.52);
}
.widget .widget_tooltip:after {
	content: '';
	position: absolute;
	width: 0;
	height: 0;
	border: 10px solid transparent;
	top: 50%;
	right: -19px;
	transform: translateY(-50%);
	border-left-color: #fff;
}
.widget .widget_icon_link:hover .widget_tooltip {
	opacity: .85;
}
@media only screen and (max-width: 576px) {
	.widget .widget_wrapper {
		bottom: 25px;
		right: 25px;
	}
}
@keyframes widget_btn-pulsate {
	0% {
		transform: scale(1,1);
	}
	25% {
		transform: scale(1.1,1.1);
	}
	50% {
		transform: scale(1,1);
	}
}
@keyframes widget_pulsate {
	0% {
		transform: scale(.8,.8);
		opacity: 0;
	}
	25% {
		opacity: .4;
	}
	50% {
		transform: scale(1.3,1.3);
		opacity: 0;
	}
	100% {
		transform: scale(.8,.8);
		opacity: 0;
	}
}
