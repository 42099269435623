.section {

	.section__inner {
		width: 100%;
	}

	.section__header {
		display: flex;
		justify-content: space-between;
		padding-bottom: 20px;
		flex-wrap: wrap;
		flex-direction: row;
		align-items: center;
		width: 100%;
	}

	.section__header-morelink {
		font-size: 10px;
		text-transform: uppercase;
		text-decoration: none;
		color: var(--color-grey);

		&:hover {
			text-decoration: underline;
			color: var(--col-primary);
		}
	}

	.section__header-title {
		font-size: 32px;
		font-weight: 300;
		letter-spacing: -0.5px;
		color: var(--color-darkness-grey);
		line-height: 1;
	}

	.section__body {

	}


	@media only screen and (max-width: 576px) {
		.section__header-title {
			font-size: 22px;
		}
	}


}


@media only screen and (max-width: 576px) {
	.section.mt-4 {
		margin-top: 2rem !important;
	}
}
