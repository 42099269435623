.rounded3 {
	border-radius: 4px;
}

.rounded5 {
	border-radius: 5px;
}

.rounded10 {
	border-radius: 10px;
}

.loading_block {
	background: var(--color-white) url('/assets/svg/double_ring.svg') center center no-repeat;

	&.loaded {
		background-image: none;
	}
}

.list {
	padding: 0;
	margin-top: 0;
	margin-bottom: 0;
	list-style: none;
}

.d-menu__angle-right {
	margin-right: -5px
}

.icon10,.icon16,.icon20,.icon30 {
	display: inline-block;
	font-style: normal;
	fill: currentColor
}

.icon10 {
	font-size: 12px;
	margin-bottom: -2px;
	width: 12px;
	height: 12px
}

.icon16 {
	font-size: 16px;
	width: 16px;
	height: 16px
}

.icon16,.icon20 {
	margin-bottom: -3px
}

.icon20 {
	font-size: 20px;
	width: 20px;
	height: 20px
}

.icon30 {
	font-size: 30px;
	width: 30px;
	height: 30px
}

.icon30,.icon40 {
	margin-bottom: -3px
}

.icon40 {
	font-size: 40px;
	width: 40px;
	height: 40px
}

.flexbox {
	display: flex;
}

.row.flexbox {
	flex-direction: row;
	flex-wrap: wrap;
}


.bg-fon-img {
	width: 100%;
	height: 100%;
	display: block;
}
.bg-fon-img, .bg-img {
	background-size: cover;
	background-position: center;
}

.bg-animate:after,
.bg-animate:before {
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	content: "";
}

.bg-animate {
	position: relative;

	&:before {
		opacity: 0;
		top: 0;
		background: rgba(211,0,48,.5);
		transition: opacity .3s ease;
		z-index: 11;
		border-radius: 4px;
	}

	&:after {
		top: 40%;
		transition: opacity .3s ease;
		z-index: 1;
		background: linear-gradient(180deg,rgba(0,0,0,0) 0,rgba(21,67,123,.2) 100%);
		border-radius: 4px;
	}
}

.list-style {
	padding: 0;
	list-style: none;

	li {
		position: relative;
		margin-left: 20px;
		display: block;
		margin-bottom: 5px;

		&:before {
			content: '';
			position: absolute;
			display: block;
			left: -20px;
			top: 5px;
			background-color: var(--col-second);
			border-radius: 50%;
			width: 8px;
			height: 8px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}



.category_inner {
	align-items: normal;
}

.category_content {
	padding: 0;
}

ul.list-catalog li:nth-child(n+5) {
	display: list-item;
}

.show_hide_list {
	display: none
}


.shine {
	position: relative;
	overflow: hidden;
}

.image-container {
	float: left;
	position: relative;
	width: 100%;
	height: 100%;
	background: url(/assets/svg/loader.svg) no-repeat center center;
	background-size: 100px;

	svg,
	.image {
		height: 100%;
		left: 0;
		position: absolute;
		width: 100%;
	}
}
