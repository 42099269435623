.block {
	width: 100%;

	.block__inner {
		width: 100%;
	}

	.block-description {
		h2 {
			margin-top: 2rem;
			margin-bottom: 1.5rem;
			font-weight: 600;
		}

		h3 {
			margin-top: 1.5rem;
			margin-bottom: 1rem;
		}

		h4 {
			margin-top: 1.5rem;
			margin-bottom: 0.75rem;
		}
	}
}