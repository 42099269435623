.lazyload,
.lazyloading {
	opacity: 1;
	background-position: center;
	background-size: inherit;
	background-repeat: no-repeat;
}
.lazyloaded {
	opacity: 1;
	transition: opacity 300ms;
}