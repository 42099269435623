.breadcrumbs {
	width: 100%;

	&::-webkit-scrollbar {
		display: none;
	}

	.breadcrumbs__inner {
		width: 100%;
	}

	.breadcrumbs {
		padding: 0
	}

	.breadcrumbs__item {
		display: inline-block;
		position: relative;
		padding-right: 16px;
		margin-right: -16px
	}

	.breadcrumbs__separator {
		position: relative;
		zoom:1;bottom: -1px;
		display: inline-block;
		line-height: 18px;
		margin: 0 10px 0 9px;
		border: none;
		color: var(--color-grey)
	}

	.breadcrumbs__item-name {
		color: var(--color-darkness-grey);
	}

	a {
		text-decoration: none;

		&:hover span {
			color: var(--color-dark)
		}
	}
}


.mobile .breadcrumbs__item:hover .breadcrumbs__dropdown-wrapper {
	display: none
}

.mobile .breadcrumbs__item.hover .breadcrumbs__dropdown-wrapper {
	display: block
}


@media (max-width: 576px) {
	.breadcrumbs {
		display: flex;
		white-space: nowrap;
		overflow: hidden;
		overflow-x: auto;
		scrollbar-width: none;
	}
}