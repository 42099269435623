.categories {
	.col-item {
		min-height: 100px;
	}

	.categories__inner {
		width: 100%;
	}

	.categories__item {
		height: 100%;
		min-height: 100%;
		position: relative;
		padding: 0 0 22px;

		&:hover {
			.categories__image-style {
				transform: scale(1.1);
			}
		}
	}

	.categories__image {
		height: 100%;
		box-shadow: 0 4px 16px #00339914, 0 2px 2px #00339914;

		* {
			height: 100%;
		}
	}

	.categories__image-inner {
		height: 100%;
		transition: transform ease .8s;
	}

	.categories__image-style {
		position: relative;
		width: 100%;
		height: 100%;
		display: block;
		background-color: var(--color-light);
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
		transition: transform ease .8s;

		&.lazyload {
			background-size: 100px;
		}

		&.lazyloaded {
			background-size: cover;
		}
	}

	.categories__image-big {
		padding-top: 47.5%;
		border-radius: 4px;
		min-height: 200px;
	}

	.categories__image-small {
		padding-top: 100%;
		border-radius: 4px;
		min-height: 200px;
	}

	.categories__inner-text {
		position: absolute;
		z-index: 11;
		background: var(--gradient-primary);
		width: 220px;
		padding: 20px;
		display: flex;
		align-items: center;
		opacity: 0.8;
		border-radius: 0 8px 8px 0;
		bottom: 70px;
		left: 0;
		right: 0;
	}

	.categories__title-inner {
		margin-bottom: 0;
		transition: all ease .2s;
		overflow: hidden;
		//transform: translateY(-2px);

		a {
			font-size: 24px;
			font-weight: 500;
			text-decoration: none;
			color: var(--color-white);
		}
	}

	@media only screen and (max-width: 576px) {

		.categories__inner-text {
			width: 140px;
			padding: 10px;
		}

		.categories__title-inner {
			a {
				font-size: 16px;
				line-height: 1;
			}
		}
	}
}