* {
	box-sizing: border-box
}

* :focus {
	outline: none
}

html {
	-webkit-text-size-adjust: 100%;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
}

body {
	font-family: "Gilroy", sans-serif;
	font-size: 14px;
	font-weight: 300;
	line-height: 19px;
	color: var(--color-dark);
	text-align: left;
	background-color: var(--col-slate-100);
	cursor: default;
	user-select: text;
	overflow-y: scroll;
}

a {
	color: var(--col-primary);
	transition: all .3s linear;

	&:hover {
		text-decoration: none;
		color: var(--col-second);
	}
}

strong {
	font-weight: 700;
}

.icon {
	display: inline-block;
	fill: currentColor;
}

.--svg {
	fill: currentColor;
}

.page {
	display: flex;
	flex-direction: column;
	position: relative;
}

.wrapper_inner {
	position: relative;
	display: flex;
	justify-content: center;
	transition: all .3s linear;
	margin: 0 auto;
	height: inherit;
	max-width: @max-width;
}

main {
	min-height: 500px;
}

.fw-regular {
	font-family: Gilroy-Regular, sans-serif;
}

.fw-bold {
	font-family: Gilroy-Bold, sans-serif;
}

.fw-black {
	font-family: Gilroy-Black, sans-serif;
}

@media(max-width: 441px) {
	h1, h2, h3, h4, h5, h6 {
		line-height: 1;
	}
}
