

.block__item-breadcrumbs {
	margin-bottom: 25px;
 }

.block__item-position {
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: sticky;
	max-width: 100%;
	flex-grow: 1;
	top: 65px;
	z-index: 100;
}


/* ------------------------------------------------------------------------------- */

.block__item-main {
	display: flex;
	flex-wrap: wrap;
	align-items: start;
	margin: 0 0 50px;
	gap: 25px;
	width: 100%;

	.block__item-main-left {
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: relative;
		width: 60%;
	}

	.block__item-main-right {
		display: flex;
		flex-direction: column;
		justify-content: center;
		position: sticky;
		width: 35%;
		flex-grow: 1;
		top: 65px;
		z-index: 100;
	}

	.block__item-main-tabs {
		display: flex;
		flex-direction: column;
		position: relative;
		width: 60%;

		margin: 35px 0;

		.nav-tabs {
			border-color: transparent;

			.nav-item {
				font-size: 20px;
				font-weight: 700;
				color: var(--color-grey);
				margin-right: 25px;
			}

			.nav-link {
				padding: 0;
				color: var(--color-grey);

				&.active {
					background: none;
					border-color: transparent;
					color: var(--color-dark);
					border-bottom: 3px solid var(--col-primary);
				}
			}
		}

		.tab-content {
			margin-top: 25px;
		}

		.block__item-tabs-header {
			display: block;
			font-size: 24px;
			margin-bottom: 25px;
		}

		.item-detail {
			display: flex;
			padding-bottom: 10px;
			.item-detail-left {
				position: relative;
				width: 50%;
				background-image: radial-gradient(ellipse,var(--color-grey) 0, var(--color-grey) .5px,transparent 1.25px);
				background-size: 5px 5px;
				background-repeat: repeat-x;
				background-position: 0 100%;

				span {
					background: var(--col-slate-100);
					padding-right: 10px;
					padding-bottom: 3px;
					position: relative;
					font-weight: 700;
				}
			}

			.item-detail-right {
				margin-left: 10px;
			}
		}
	}

	.block__item-gallery {
		display: flex;
		flex-direction: column;
		align-content: center;
		padding: 25px;
		background: var(--color-white);
		border-radius: 10px;
	}

	.block__item-gallery-badge {
		position: absolute;
		left: 40px;
		top: 40px;
		z-index: 100;

		img {
			max-width: 200px;
		}
	}

	.block__item-gallery-full {
		margin-bottom: 15px;
		position: relative;
		border: 1px solid var(--color-grey);
	}

	.block__item-gallery-thumbs {
		display: flex;
	}

	.block__item-gallery-thumbs-item {
		margin-right: 20px;
		border: 1px solid var(--color-grey);

		&.--active {
			border-color: var(--col-primary);
		}
	}

	h1 {
		font-size: 22px;
		font-weight: 500;

		span {
			display: block;
			font-weight: 700;
		}
	}

	.block__item-status {
		display: flex;
		align-items: center;
		gap: 10px;
		margin-bottom: 15px;

		&.--instock {
			color: var(--col-green-500);
			fill: var(--col-green-500);
		}

		&.--outtock i {
			color: var(--col-primary-500)
		}

		.block__item-status_icon {

		}
	}

	.block__item-discount {
		display: flex;
		align-items: flex-start;
		height: 20px;
		margin-bottom: 10px;

		.block__item-sales_old {
			padding-top: 3px;
			line-height: 1;
			text-decoration: line-through;
			color: var(--color-grey);
		}

		.block__item-sales_discount {
			margin-left: 10px;
			padding: 3px 7px;
			font-size: 12px;
			line-height: 1;
			border-radius: 2px;
			color: var(--color-white);
			background-color: var(--col-green-500);
		}
	}

	.block__item-price {
		margin-bottom: 25px;

		.block__item-price-sign {
			color: var(--color-grey);
			display: block;
			margin-bottom: 10px;
		}

		.block__item-price-price {
			font-size: 42px;
			font-weight: 700;

			span {
				font-size: 24px;
			}
		}
	}

	.block__item-chars {
		display: flex;
		flex-direction: row;
		width: 80%;
		margin-bottom: 25px;
		font-size: 14px;

		.block__item-chars-list {
			flex-grow: 1;
			width: 100%;
			padding: 0;
			margin: 0;

			.block__item-chars-item {
				display: flex;
				margin-bottom: 5px;
				justify-content: space-between;
				white-space: nowrap;
			}

			.block__item-chars-name {
				display: flex;
				flex-grow: 1;
				text-overflow: ellipsis;
				font-weight: 700;

				&:after {
					content: '';
					flex-grow: 1;
					display: inline-block;
					border-bottom: 1px dotted var(--color-grey);
				}
			}

			.block__item-chars-value {

			}
		}
	}

	.block__item-more {
		margin-bottom: 25px;

		span {
			color: var(--col-second);
			font-weight: 700;
			cursor: pointer;

			&:hover {
				color: var(--col-primary);
			}
		}
	}

	.block__item-warranty {
		display: flex;
		align-items: center;
		margin-bottom: 35px;

		svg {
			fill: var(--col-primary);
			margin-right: 10px;
		}

		span {
			font-size: 16px;
			color:  var(--col-primary);
		}

		strong {
			font-weight: 700;
		}
	}

	.block__item-buy {
		margin-bottom: 25px;

		.btn-buy {
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 15px;
			width: 100%;
			height: 50px;
			font-size: 16px;
			font-weight: 700;
			line-height: 1;
			background: var(--col-primary);
			border-radius: 10px;
			color: var(--color-white);
			text-decoration: none;
			transition: .3s;
			fill: currentColor;

			&:hover {
				background: var(--col-primary-900);
			}

			&.--in-cart {
				background-color: var(--col-green-600);
				border-color: var(--col-green-600);

				&:hover {
					background-color: var(--col-green-700);
					border-color: var(--col-green-700);
				}

				&:active {
					background-color: var(--col-green-800);
					border-color: var(--col-green-800);
				}
			}
		}

		.o-btn-buy {
			height: 50px;
			font-size: 16px;
			font-weight: 700;
			line-height: 1;
			border-radius: 10px;
		}
	}
}

.block__item-consultation {
	display: flex;
	flex-direction: column;
	align-content: center;
	padding: 25px;
	background: var(--color-white);
	border-radius: 10px;
	margin-top: 15px;
	box-shadow: 0 8px 16px #0033990a, 0 4px 4px #00339914;

	.block__item-consultation-header {
		display: flex;
		width: 100%;
		justify-content: center;
		font-size: 18px;
		font-weight: 700;
		margin-bottom: 25px;
	}

	.block__item-consultation-icon {
		display: flex;
		width: 100%;
		justify-content: center;
		fill: var(--col-primary);
		margin-bottom: 25px;
	}

	.block__item-consultation-descr {
		display: flex;
		width: 100%;
		justify-content: center;
		text-align: center;
		font-size: 16px;
		margin-bottom: 15px;
	}

	.block__item-consultation-btn {
		font-size: 16px;
		font-weight: 700;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 50px;
		flex-shrink: 0;
		line-height: 1;
		background: var(--color-white);
		border: 1px solid var(--col-primary);
		border-radius: 10px;
		color: var(--color-dark);
		text-decoration: none;
		transition: .3s;

		&:hover {
			background: var(--col-primary);
			color: var(--color-white);
		}
	}
}

.block__item-others {
	margin-bottom: 25px;

	.block__item-others-header {
		display: block;
		font-size: 24px;
		margin-bottom: 25px;
	}
}


@media (max-width: 576px) {
	.block__item-main {
		width: 100%;
		margin: 0;

		.block__item-main-left {
			width: 100%;
		}

		.block__item-main-right {
			width: 100%;
			position: relative;
			margin-bottom: 35px;
		}

		.block__item-gallery {
			padding: 15px;
		}

		.block__item-gallery-badge {
			left: 20px;
			top: 20px;

			img {
				max-width: 100px;
			}
		}

		.block__item-main-tabs {
			width: 100%;

			.nav-tabs {
				flex-wrap: wrap;
				flex-direction: column;
				gap: 10px;

				.nav-item {
					justify-content: center;
					display: flex;
				}
			}

			.item-detail {
				flex-direction: column;

				.item-detail-left {
					background: none;
					width: 100%;
				}

				.item-detail-right {
					margin-left: 0;
				}
			}
		}
	}

	.block__item-breadcrumbs {
		display: flex;
		white-space: nowrap;
		overflow: hidden;
		overflow-x: auto;
		scrollbar-width: none;
	}
}
