.production__block-img {
	img {
		border-radius: 10px;
		max-height: 450px;
		width: 100%;
		object-fit: cover;
		height: 100%;
	}
}

.production__block-text {
	padding: 25px;
	background: var(--color-white);
	border-radius: 10px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	border: 1px solid var(--color-light-grey);


	.production__block-text-header {
		display: flex;
		color: var(--col-primary);
		font-weight: 700;
		font-size: 26px;
		line-height: 1;
	}

	p {
		font-size: 16px;
	}

	a {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 200px;
		height: 36px;
		flex-shrink: 0;
		font-weight: 700;
		line-height: 1;
		border: 1px solid var(--col-primary);
		background: 0 0;
		color: var(--col-primary);
		border-radius: 5px;
		text-transform: uppercase;
		text-decoration: none;
		cursor: pointer;
		transition: .3s;

		&:hover {
			border: 1px solid var(--col-primary);
			background: var(--col-primary);
			color: var(--color-white);
		}
	}
}
