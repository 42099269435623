
.btn-minus, .btn-plus, .btn-coupon, .btn-delete {
	min-width      : 25px;
	font-weight: 500;
	border     : solid 1px var(--col-slate-300);
}

.btn-minus:hover,
.btn-plus:hover,
.btn-coupon:hover,
.btn-delete:hover {
	color: var(--col-primary-500);
	border     : solid 1px var(--col-primary-500);
}

.basket-offers {
	display: flex;
	gap    : 15px;
}

.order-checkbox {
	min-width: 200px;
	min-height: 80px;
}

.order-checkbox input {
	position: absolute;
	visibility: hidden;
}

.btn-delivery {
	padding: 0 15px;
	width: 25%;
	text-align: center;
}

.order-thumb {
	max-width: 100px;
}


.payment {
	display: flex;
	justify-content: center;
}

@media (max-width: 767px) {
	.basket-offers {
		flex-direction: column;
	}

	.btn-delivery {
		width     : 100%;
	}
}


.o-btn {
	cursor: pointer;
	font-size: 14px;
	color           : var(--col-slate);
	background-color: var(--color-white);
	border          : 1px solid var(--col-slate);
	fill            : currentColor;
	display         : flex;
	justify-content : center;
	align-items     : center;
	gap             : 15px;
	padding         : 10px 0;
	border-radius   : 5px;
	text-decoration : none;
	transition      : .3s;
}

.o-btn:hover {
	text-decoration : none;
	color           : var(--color-white);
	background-color: var(--col-slate);
	border          : 1px solid var(--col-slate);
}

.o-btn:active {
	color     : var(--color-white);
	box-shadow: 5px 5px 5px rgba(9, 12, 18, .15) inset;
	background: var(--col-slate-600);
	border-color: 1px solid var(--col-slate);
}


.o-btn.--active {
	color           : var(--color-white);
	background-color: var(--col-primary);
	border          : 1px solid var(--col-primary);
}

.o-btn.--active:hover {
	color           : var(--color-white);
	background-color: var(--col-primary-600);
	border          : 1px solid var(--col-slate);
}

.o-btn.--active:active {
	color       : var(--color-white);
	box-shadow  : 5px 5px 5px rgba(9, 12, 18, .15) inset;
	background  : var(--col-primary-800);
	border-color: 1px solid var(--col-slate);
}
