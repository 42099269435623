.footer {
	color: var(--color-white);
	background: var(--col-primary);

	.footer__inner {

	}

	.footer__middle {
		padding-top: 20px;
		margin: 20px 0;
	}

	.footer__bottom {
		padding-bottom: 20px;
		font-size: 12px;
		font-weight: 600;
		line-height: 14px;
	}

	.footer-area {
		margin-bottom: 15px;
		display: flex;
		flex-direction: column;
	}

	@media only screen and (max-width: 576px) {
		.footer-area {
			margin-bottom: 20px;
			align-items: center;
			align-content: center;
			text-align: center;
		}

		.footer-area__nav li {
			text-align: center;
		}

		.footer-area__worktime {
			.item {
				text-align: center;
			}
		}

		.footer-area__phone {
			text-align: center;
		}
	}

	.footer-area__title {
		font-size: 16px;
		font-weight: 700;
		margin-bottom: 20px;
	}

	.footer-area__nav {
		margin-bottom: -7px;

		li {
			margin-bottom: 7px;
		}

		a {
			display: inline-block;
			color: var(--color-white);
			text-decoration: none;
			transition: all .3s linear;

			&:hover {
				text-decoration: underline;
			}
		}
	}

	.footer-area__worktime {
		margin-bottom: -14px;

		.item {
			margin-bottom: 14px;
		}

		.item-time {
			color: var(--color-grey);
		}
	}

	.footer-area__phone {
		margin-bottom: 14px;
		display: flex;
		flex-direction: column;
	}

	.footer-area__phone-title {
		color: var(--color-white);
		padding-bottom: 4px;
		font-size: 13px;
	}

	.footer-area__phone-link {
		line-height: 1;
		display: inline-block;
		color: var(--color-white);
		font-size: 22px;
		font-weight: 700;
		text-decoration: none;
		padding-top: 3px;
		transition: all .3s linear;
		margin-bottom: 15px;

		&:hover {
			opacity: 0.7;
		}
	}

	.footer__request-callback {
		margin-bottom: 14px;
	}

	.footer__request-callback-btn {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 200px;
		height: 36px;
		flex-shrink: 0;
		font-weight: 400;
		line-height: 1;
		border: 1px solid var(--color-white);
		background: transparent;
		color: var(--color-white);
		border-radius: 5px;
		text-decoration: none;
		cursor: pointer;
		transition: all .3s ease;

		svg {
			fill: currentColor;
			margin-right: 15px;
		}

		&:hover {
			background: var(--color-white);
			color: var(--col-primary);

			svg {
				fill: currentColor;
			}
		}

		&:active {
			color: var(--color-white);
			box-shadow: 5px 5px 5px rgb(9 12 18 / 15%) inset;
			background: var(--col-primary-900);
			border-color: var(--col-primary-900);

			svg {
				fill: currentColor;
			}
		}
	}

	.footer__logo {
		cursor: pointer;
		text-decoration: none;
		display: flex;
		max-width: 250px;
		margin-bottom: 15px;

		svg {
			width: 100%;
			height: auto;
			fill: var(--color-white);
		}
	}

	.footer__attention {
		.footer-area {
			margin: 0;
			padding: 10px 0;
		}

		background: var(--color-white);

		p {
			font-size: 16px;
			font-weight: 100;
			text-transform: uppercase;
			text-align: center;
			color: var(--color-grey);
			padding: 0;
			margin: 0;
		}
	}
}

